import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Profile from './Profile';
import Signin from './Signin';
import Logo from './Logo';
import Notifications from './Notifications';
import Navigation from './Navigation';
import ThemeToggle from './ThemeToggle';

const Header: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  const borderStyle = `border-b border-gray-200 dark:border-gray-700 ${scrollY > 0 ? 'shadow-sm' : ''}`;

  return (
    <header className={`bg-white dark:bg-gray-800 sticky top-0 z-30 ${borderStyle}`}>
      <div className="flex items-center justify-between h-12 px-2">
        <div className="flex items-center space-x-4">
          <Logo />
          <Navigation />
        </div>
        <div className="flex items-center justify-end space-x-1">
          <ThemeToggle />
          {!isAuthenticated && !isLoading && (
            <Signin />
          )}
          <Notifications />
          <Profile />
        </div>
      </div>
    </header>
  );
};

export default Header;