import { Link, useLocation } from 'react-router-dom';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import { useAuth0 } from '@auth0/auth0-react';
import { cn } from "@/lib/utils";

const menuItems = [
  {
    label: 'Getting Started',
    href: '/getting-started',
    protected: false,
    visible: true,
  },
  {
    label: 'Products',
    href: '/products',
    protected: false,
    visible: true,
  },
  {
    label: 'Components',
    href: '/components',
    protected: false,
    visible: true,
  },
  {
    label: 'Pricing',
    href: '/pricing',
    protected: false,
    visible: true,
  },
];

export default function Navigation() {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  return (
    <NavigationMenu>
      <NavigationMenuList className="flex gap-4">
        {menuItems
          .filter((item) => item.visible)
          .filter((item) => (item.protected && isAuthenticated) || !item.protected)
          .map((item) => {
            const isActive = location.pathname === item.href;
            return (
              <NavigationMenuItem key={item.href}>
                <Link to={item.href}>
                  <NavigationMenuLink
                    className={cn(
                      "text-sm transition-colors hover:text-primary dark:hover:text-primary",
                      isActive
                        ? "text-primary"
                        : "dark:text-muted-foreground"
                    )}
                  >
                    {item.label}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            );
          })}
      </NavigationMenuList>
    </NavigationMenu>
  )
}