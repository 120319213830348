import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import Header from '@/components/header';
import Profile from '@/components/profile';
import Components from '@/pages/Components';
import { Dashboard } from '@/pages/Home';
import Sidebar from '@/components/sidebar';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ component, ...args }: { component: React.ComponentType<any> }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

function App() {
  return (
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<AuthenticatedLayout />}>
              <Route path="dashboard" element={<ProtectedRoute component={Dashboard} />} />
              <Route path="profile" element={<ProtectedRoute component={Profile} />} />
              <Route path="components" element={<ProtectedRoute component={Components} />} />
            </Route>
          </Routes>
        </main>
      </Auth0Provider>
    </BrowserRouter>
  );
}

export default App;

const AuthenticatedLayout = () => {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return <Outlet />;
  }
  return (
    <div className="grid h-screen w-full pl-[56px]">
      <Sidebar />
      <div className="flex flex-col">
        <Outlet />
      </div>
    </div>
  );
};
