import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { Bell } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { Tooltip } from '@/components/ui/tooltip';

const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated} = useAuth0();
  if (!isAuthenticated) return null;
  return (
    <div>
      <DropdownMenu modal={false}>
        <Tooltip title="Notifications">
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="rounded-full" onClick={() => setIsOpen(!isOpen)}>
              <Bell className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
        </Tooltip>
        <DropdownMenuContent align="end" className="w-[300px]">
          <div className="flex justify-between items-center w-full">
            <DropdownMenuLabel>Notifications</DropdownMenuLabel>
            <Button variant="ghost" size="xs">Mark as Read</Button>
          </div>
          <DropdownMenuSeparator />
          <Link to="/notification/detail?id=1">
            <DropdownMenuItem>
              <div className="flex flex-col space-y-1">
                <div className="text-sm truncate">Dummy Notification Title</div>
                <div className="text-xs text-gray-500">Dummy Notification Body</div>
                <div className="text-xs text-gray-500">A few minutes ago</div>
              </div>
            </DropdownMenuItem>
          </Link>
          <Link to="/notification/detail?id=1">
            <DropdownMenuItem>
              <div className="flex flex-col space-y-1">
                <div className="text-sm truncate">Dummy Notification Title</div>
                <div className="text-xs text-gray-500">Dummy Notification Body</div>
                <div className="text-xs text-gray-500">An hour ago</div>
              </div>
            </DropdownMenuItem>
          </Link>
          <Link to="/notification/detail?id=1">
            <DropdownMenuItem>
              <div className="flex flex-col space-y-1">
                <div className="text-sm truncate">Dummy Notification Title</div>
                <div className="text-xs text-gray-500">Dummy Notification Body</div>
                <div className="text-xs text-gray-500">Yesterday</div>
              </div>
            </DropdownMenuItem>
          </Link>
          <Link to="/notification/detail?id=1">
            <DropdownMenuItem>
              <div className="flex flex-col space-y-1">
                <div className="text-sm truncate">Dummy Notification Title</div>
                <div className="text-xs text-gray-500">Dummy Notification Body</div>
                <div className="text-xs text-gray-500">2 days ago</div>
              </div>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/notifications">
            <DropdownMenuItem>
              See all notifications
            </DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default Notifications;