import {
  Book,
  Bot,
  Code2,
  LifeBuoy,
  Settings2,
  SquareIcon,
  User2Icon,
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
} from '@/components/ui/tooltip';

const Sidebar = () => {
  return (
    <aside className="inset-y fixed left-0 z-20 flex h-full flex-col border-r">
      <nav className="grid gap-1 p-2">
        <Tooltip title="Playground" side="right" sideOffset={5}>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-lg bg-muted"
            aria-label="Playground"
          >
            <SquareIcon className="size-5" />
          </Button>
        </Tooltip>
        <Tooltip title="Models" side="right" sideOffset={5}>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-lg"
            aria-label="Models"
          >
            <Bot className="size-5" />
          </Button>
        </Tooltip>
        <Tooltip title="API" side="right" sideOffset={5}>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-lg"
            aria-label="API"
          >
            <Code2 className="size-5" />
          </Button>
        </Tooltip>
        <Tooltip title="Documentation" side="right" sideOffset={5}>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-lg"
            aria-label="Documentation"
          >
            <Book className="size-5" />
          </Button>
        </Tooltip>
        <Tooltip title="Settings" side="right" sideOffset={5}>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-lg"
            aria-label="Settings"
          >
            <Settings2 className="size-5" />
          </Button>
        </Tooltip>
      </nav>
      <nav className="mt-auto grid gap-1 p-2">
        <Tooltip title="Help" side="right" sideOffset={5}>
          <Button
            variant="ghost"
            size="icon"
            className="mt-auto rounded-lg"
            aria-label="Help"
          >
            <LifeBuoy className="size-5" />
          </Button>
        </Tooltip>
        <Tooltip title="Account" side="right" sideOffset={5}>
          <Button
            variant="ghost"
            size="icon"
            className="mt-auto rounded-lg"
            aria-label="Account"
          >
            <User2Icon className="size-5" />
          </Button>
        </Tooltip>
      </nav>
    </aside>
  );
}

export default Sidebar;
