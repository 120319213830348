import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Page } from "@/components/ui/page";

const Components: React.FC = () => {
  return (
    <Page title="Components">
      <div className="flex items-center space-x-2">
        <Switch id="airplane-mode" />
        <Label htmlFor="airplane-mode">Airplane Mode</Label>
      </div>
    </Page>
  );
}

export default Components;
