import { useAuth0 } from "@auth0/auth0-react";
import LogoIcon from '@/logo-icon.svg';
import { Tooltip } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { Page } from '@/components/ui/page';

const Profile: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (<Page title="Profile">Loading ...</Page>);
  }

  return isAuthenticated && user ? (
    <Page title="Profile">
      <img src={user.picture} alt={user.name} />
      <img src={LogoIcon} alt={user.name} />
      <h2>{user.name}</h2>
      <Tooltip title="Click to send email">
        <Button>{user.email}</Button>
      </Tooltip>
      <pre>{JSON.stringify(user, null, 2)}</pre>
    </Page>
  ) : null;
};

export default Profile;