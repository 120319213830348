import logo from '@/logo.svg';
import { Link } from 'react-router-dom';

const Logo: React.FC = () => {
  return (
    <Link to="/">
      <img src={logo} alt="Logo" width={120} />
    </Link>
  );
};

export default Logo;
