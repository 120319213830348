import * as React from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"

import { cn } from "@/lib/utils"

const TooltipProvider = TooltipPrimitive.Provider

interface TooltipProps {
  children: React.ReactNode;
  title: string;
  sideOffset?: number;
  className?: string;
  [key: string]: any;
}

const Tooltip: React.FC<TooltipProps> = ({ children, title, side = "bottom", align = "center", sideOffset = 4, className, ...props }) => (
  <TooltipProvider>
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger asChild>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        sideOffset={sideOffset}
        side={side}
        align={align}
        className={cn(
          "z-50 overflow-hidden rounded-md border bg-black px-2 py-1 text-xs text-white shadow-sm animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className
        )}
        {...props}
      >
        {title}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  </TooltipProvider>
)

export { TooltipProvider, Tooltip }