import { Moon, Sun } from "lucide-react"
import { useTheme } from "@/components/theme-provider"
import { Button } from "@/components/ui/button"
import { ComponentProps, useState } from "react"

export default function ThemeToggle({ buttonProps }: { buttonProps?: ComponentProps<typeof Button> }) {
  const { setTheme, theme } = useTheme()
  const [currentTheme, setCurrentTheme] = useState(theme)

  const toggleTheme = () => {
    const newTheme = currentTheme === "light" ? "dark" : "light"
    setTheme(newTheme)
    setCurrentTheme(newTheme)
  }

  return (
    <Button variant="ghost" size="icon" className="rounded-full" onClick={toggleTheme} {...buttonProps}>
      <Sun className={`h-[1.2rem] w-[1.2rem] transition-all ${currentTheme === "dark" ? "rotate-90 scale-0" : "rotate-0 scale-100"}`} />
      <Moon className={`absolute h-[1.2rem] w-[1.2rem] transition-all ${currentTheme === "dark" ? "rotate-0 scale-100" : "rotate-90 scale-0"}`} />
      <span className="sr-only">Toggle theme</span>
    </Button>
  )
}