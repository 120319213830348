import { Button } from '@/components/ui/button';
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRight, ChevronRight } from "lucide-react";

const Signin: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button size="sm" onClick={() => loginWithRedirect()} color="primary" className="rounded-full group min-w-[100px]">
      Sign in
      <ChevronRight strokeWidth={3} className="h-4 w-4 ml-1 opacity-100 group-hover:opacity-0 group-hover:translate-x-1 transition-transform duration-200 ease-in-out transform" />
      <ArrowRight strokeWidth={3} className="h-4 w-4 ml-1 absolute opacity-0 group-hover:opacity-100 group-hover:translate-x-6 translate-x-5 transition-transform duration-200 ease-in-out transform" />
    </Button>
  );
};

export default Signin;
